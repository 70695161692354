












































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    TrashCan16,
    Edit16,
    GenericModalDelete
  }
})
export default class EventSponsors extends Mixins(FlashMessageMixin, PermissionsMixin) {
  eventId = this.$route.params.id
  sponsors: Array<any> = []
  filteredData: Array<any> = []
  deleteModalIsOpened = false
  totalSponsors = 0
  selectedEventSponsor = {
    id: '',
    category: '',
    sponsorId: '',
    eventId: ''
  }

  fetchSponsors (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.$route.params.id}/sponsors`)
      .then(response => {
        this.sponsors = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalSponsors = response.data.data.length
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Patrocinador foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchSponsors()
  }

  search (searchString: string) {
    this.filteredData = this.sponsors.filter((sponsor: any) => {
      const allValues = Object.keys(sponsor).map(key => sponsor[key])

      const isMatch = allValues.some((value: string) => {
        return value.toLowerCase().includes(searchString.toLowerCase())
      })

      if (isMatch) {
        return sponsor
      }
    })
  }

  onPageChange (pagination: Pagination) {
    this.fetchSponsors(pagination.page, pagination.length)
  }

  get deleteRequestBody () {
    return { eventSponsorId: [isEmpty(this.selectedEventSponsor) ? '' : this.selectedEventSponsor.id] }
  }

  get deleteUrl () {
    return isEmpty(this.selectedEventSponsor) ? '' : `/event/${this.eventId}/sponsors/${this.selectedEventSponsor.id}`
  }
}
